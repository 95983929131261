const BenachrichtigungTags = {
    AENDERUNG: "aenderung",
    AUFGABE: "aufgabe",
    AUSBILDUNG: "ausbildung",
    DIENST: "dienst",
    DOKUMENT: "dokument",
    EINTEILUNG: "einteilung",
    ENTFERNT: "entfernt",
    FREIE_NACHRICHT: "freie_nachricht",
    GEBURTSTAG: "geburtstag",
    GELESEN: "gelesen",
    HANDLUNGSBEDARF: "handlungsbedarf",
    KURS: "kurs",
    MATERIAL: "material",
    NEU: "neu",
    PERSOENLICH: "persoenlich",
    PERSONAL: "personal",
    PINNWAND: "pinnwand",
    RECHNUNG: "rechnung",
    SMS: "sms",
    SONSTIGES: "sonstiges",
    SYSTEM: "system",
    TERMIN: "termin",
    TERMINABSPRACHE: "terminabsprache",
    WARNUNG: "warnung",
    UEBERPRUEFUNG: "ueberpruefung",
    UNGELESEN: "ungelesen",
} as const;

export {BenachrichtigungTags};
