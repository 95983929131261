<template>
    <div class="tw-bg-gray-50">
        <div style="position: relative;z-index: 1">
            <img
                class="tw-rounded-lg"
                src="static/content/wunschliste_info_page/office_1500x430.jpg"
            >
        </div>
        <div class="tw-py-8 sm:tw-py-12 sm:tw-px-2 lg:tw-px-4">
            <div
                class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-x-16 tw-gap-y-6 tw-items-center tw-justify-between"
            >
                <div
                    class="tw-flex-auto"
                >
                    <h2
                        class="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-[#003134]"
                    >
                        Wir haben uns dazu entschieden, die Wunschliste zu entfernen. Warum?
                    </h2>
                    <p class="tw-mt-4 tw-text-gray-500">
                        Uns ist es sehr wichtig, den HiOrg-Server für euch kontinuierlich zu verbessern und passende Lösungen für eure aktuellen Herausforderungen zu finden.<br>
                        <br>
                        Die Wunschliste konnte eine lange Zeit als nützliches Hilfsmittel dienen, um den Bedarf der Kunden darzustellen. Seit einiger Zeit wird sie unserem und eurem Anspruch leider nicht mehr gerecht.<br>
                        <br>
                        Wir möchten uns stärker fokussieren, um schnellere und effektivere Lösungen zu entwickeln.<br>
                        <br>
                        Im Bereich des Ehrenamts und der Dienstplanung möchten wir die Zusammenarbeit der Organisationen stärken und die Planung gemeinsamer Dienste effektiver und einfacher gestalten. Im Segment der Kursverwaltung möchten wir die digitale Lehrgangsdokumentation vorantreiben und durchsetzen.
                        <br>
                        <br>
                        Wir freuen uns darauf, gemeinsam mit euch das Ehrenamt zu stärken und das Thema Erste-Hilfe in Deutschland voranzubringen.
                    </p>
                </div>
                <div class="tw-flex-none">
                    <video
                        width="270"
                        height="480"
                        controls
                        class="tw-rounded-lg"
                    >
                        <source src="static/content/wunschliste_info_page/hiorg_news_wunschliste.mp4">
                    </video>
                </div>
            </div>
            <div class="tw-mt-16 tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-grid-cols-3">
                <div class="sm:tw-flex lg:tw-block">
                    <div class="sm:tw-flex-shrink-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="3em"
                            viewBox="0 0 512 512"
                            fill="#fd5540"
                        ><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M192 416c0-17.7-14.3-32-32-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H309.3c-6.9 0-13.7 2.2-19.2 6.4L192 464V416zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h64 32v32 48c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM180.7 132.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 208l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 230.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 208l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 185.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z" /></svg>
                    </div>
                    <div class="tw-mt-4 sm:tw-ml-6 sm:tw-mt-0 lg:tw-ml-0 lg:tw-mt-6">
                        <h3
                            class="tw-text-sm tw-font-bold tw-text-[#fd5540]"
                        >
                            Problem melden
                        </h3>
                        <p class="tw-mt-2 tw-text-sm tw-text-gray-500">
                            Natürlich könnt Ihr uns weiterhin Probleme mit dem System melden. Wie das funktioniert findet Ihr hier:<br>
                            <a href="todo_add.php?ueb=fm">System-Fehler melden</a>
                        </p>
                    </div>
                </div>
                <div class="sm:tw-flex lg:tw-block">
                    <div class="sm:tw-flex-shrink-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="3em"
                            viewBox="0 0 512 512"
                            fill="#c49a40"
                        ><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M160 64c-17.7 0-32 14.3-32 32V416c0 11.7-3.1 22.6-8.6 32H432c26.5 0 48-21.5 48-48V96c0-17.7-14.3-32-32-32H160zM64 480c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64v32c-17.7 0-32 14.3-32 32V416c0 17.7 14.3 32 32 32s32-14.3 32-32V96c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V400c0 44.2-35.8 80-80 80H64zM384 112c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16zM160 304c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16zm32-144H320V128H192v96zM160 120c0-13.3 10.7-24 24-24H328c13.3 0 24 10.7 24 24V232c0 13.3-10.7 24-24 24H184c-13.3 0-24-10.7-24-24V120z" /></svg>
                    </div>
                    <div class="tw-mt-4 sm:tw-ml-6 sm:tw-mt-0 lg:tw-ml-0 lg:tw-mt-6">
                        <h3
                            class="tw-text-sm tw-font-bold tw-text-[#c49a40]"
                        >
                            Neuigkeiten
                        </h3>
                        <p class="tw-mt-2 tw-text-sm tw-text-gray-500">
                            Aktuelles und weitere Neuigkeiten werden in unserem Blog veröffentlicht. Dieser ist hier zu finden:<br>
                            <a
                                href="https://info.hiorg-server.de/admin-blog/"
                                target="_blank"
                            >Zum Blog</a>
                        </p>
                    </div>
                </div>
                <div class="sm:tw-flex lg:tw-block">
                    <div class="sm:tw-flex-shrink-0">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="3em"
                            viewBox="0 0 640 512"
                            fill="#505e7c"
                        ><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M429.7 135.7c10.3-4.9 21.9-7.7 34.3-7.7c44.2 0 80 35.8 80 80c0 7.7-1.1 15-3 22c-4.1 14.5 2.5 30 15.8 37.1C587.3 283.2 608 315.2 608 352c0 53-43 96-96 96H144C82.1 448 32 397.9 32 336c0-48.8 31.2-90.3 74.8-105.7c12.3-4.3 20.8-15.7 21.3-28.8C131.5 125 194.6 64 272 64c48.7 0 91.7 24.1 117.8 61.2c9 12.7 25.8 17.2 39.9 10.5zM416 106.8C384.1 61.5 331.5 32 272 32c-94.6 0-171.7 74.6-175.8 168.1C40.2 219.9 0 273.2 0 336c0 79.5 64.5 144 144 144H512c70.7 0 128-57.3 128-128c0-49.1-27.7-91.8-68.3-113.2c2.8-9.8 4.3-20.1 4.3-30.8c0-61.9-50.1-112-112-112c-17.2 0-33.5 3.9-48 10.8zM512 368c0-8.8-7.2-16-16-16H304c-8.8 0-16 7.2-16 16s7.2 16 16 16H496c8.8 0 16-7.2 16-16zM240 352H144c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16zM128 304c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zm272-16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H400z" /></svg>
                    </div>
                    <div class="tw-mt-4 sm:tw-ml-6 sm:tw-mt-0 lg:tw-ml-0 lg:tw-mt-6">
                        <h3
                            class="tw-text-sm tw-font-bold tw-text-[#505e7c]"
                        >
                            Du willst uns deine Gedanken mitteilen?
                        </h3>
                        <p class="tw-mt-2 tw-text-sm tw-text-gray-500">
                            Wenn ihr generell Anregungen, Ideen oder Verbesserungsvorschläge habt, schreibt uns eine E-Mail:<br>
                            <a
                                href="https://info.hiorg-server.de/kontakt/#kontaktform"
                                target="_blank"
                            >Zum Kontaktformular</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
</style>
