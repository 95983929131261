import {Model} from "coloquent";

export default class Organisation extends Model {
    protected jsonApiType = "organisationen";

    protected pageSize = 30;

    public override getJsonApiBaseUrl(): string {
        return "/api_private";
    }

    public getBezeichnung(): string {
        return String(this.getAttribute("bezeichnung"));
    }

    public setBezeichnung(bezeichnung: string): void {
        this.setAttribute("bezeichnung", bezeichnung);
    }

    public getKuerzel(): string {
        return String(this.getAttribute("kuerzel"));
    }

    public setKuerzel(kuerzel: string): void {
        this.setAttribute("kuerzel", kuerzel);
    }
}
