import {Model, PaginationStrategy} from "coloquent";
import type {AktionFromApi} from "./AktionFromApi";

export default class BenachrichtigungApiModel extends Model {
    public static pageSize = 50;

    protected static paginationStrategy = PaginationStrategy.PageBased;

    protected jsonApiType = "benachrichtigungen";

    public getJsonApiBaseUrl(): string {
        return "/api_private";
    }

    public getGelesenAm(): string | null {
        return this.getAttribute("gelesen_am") as string | null;
    }

    public setGelesenAm(gelesen_am: string | null): void {
        this.setAttribute("gelesen_am", gelesen_am);
    }

    public setGelesen(gelesen: boolean): void {
        this.setAttribute("gelesen", gelesen);
    }

    public getText(): string {
        return String(this.getAttribute("text"));
    }

    public getErstelltAm(): string {
        return String(this.getAttribute("erstellt_am"));
    }

    public getTags(): Array<string> {
        return this.getAttribute("tags") as Array<string>;
    }

    public getAktion(): AktionFromApi | null {
        return this.getAttribute("aktion") as AktionFromApi | null;
    }
}
