import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20240904-interviews",
        datum: "04.09.2024",
        kategorie: KategorieEnum.COMMUNITY,
        type: InsightTypeEnum.INTERVIEW,
        title: "Experteninterview HiOrg-Server PRO - Eure Meinung ist gefragt",
        lizenz: [LizenzEnum.PRO],
        content: [
            "Unsere Mission ist es, Helfenden bestmöglich zu helfen. Um ihr gerecht zu werden, ist es uns wichtig, "
            + " eure Bedürfnisse und Anforderungen genau zu verstehen. Nur so können wir HiOrg-Server zielgerichtet "
            + "anpassen und weiterentwickeln, um euch optimal zu unterstützen.",
            "💬 Dazu suchen wir <b>Nutzende, die regelmäßig mit HiOrg-Server PRO arbeiten</b> oder planen, diesen zu "
            + "verwenden und die organisatorische Aufgaben in ihrer Hilfsorganisation übernehmen. Denn ihr seid die "
            + "Experten! 🦸🚑🚒",
            "Gerne möchten wir in einem lockeren Gespräch eure Sichtweise kennenlernen und so eine weitere Perspektive "
            + "gewinnen. Ihr könnt dazu im praktischen "
            + "<a href='https://termin.hiorg-server.de/team/hiorg-server/interview-hiorg-server-pro' target='_blank'>"
            + "Terminplaner</a> unkompliziert einen Termin buchen. Vielen Dank!",
            "<strong>UPDATE:</strong><br>"
            + "Wir sind überwältigt von dem starken Interesse. Inzwischen haben zahlreiche Experten ein Interview mit "
            + "uns geplant. Aktuell sind alle Termine in den nächsten Wochen belegt. Wir werden ggf. in den nächsten "
            + "Monaten nochmals auf euch zukommen, wenn wir die Ergebnisse der ersten Runde ausgewertet haben.",
        ],
        aktions: [
            // {
            //     type: AktionTypeEnum.NACHRICHT,
            //     text: "Jetzt Lieblingstermin sichern",
            //     url: "https://termin.hiorg-server.de/team/hiorg-server/interview-hiorg-server-pro",
            // },
        ],
    },
];

export {
    insights,
};
