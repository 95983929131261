import type {PluralResponse, SaveResponse} from "coloquent";
import type {Aktion} from "./Aktion";
import type {AktionFromApi} from "./coloquent/AktionFromApi";
import type {Benachrichtigung} from "./Benachrichtigung";
import BenachrichtigungApiModel from "./coloquent/BenachrichtigungApiModel";
import type {BenachrichtigungEndpoint} from "./BenachrichtigungEndpoint";
import type {BenachrichtigungQuery} from "./BenachrichtigungQuery";
import type {BenachrichtigungenResponse} from "./BenachrichtigungenResponse";
import {CouldNotPerformApiCallException} from "../CouldNotPerformApiCallExpeption";
import {ErrorMessages} from "../../../ErrorMessages";
import type {Meta} from "./Meta";
import type {MetaFromApi} from "./coloquent/MetaFromApi";
import type {TagCount} from "./TagCount";

export class BenachrichtigungEndpointImpl implements BenachrichtigungEndpoint {
    private readonly benachrichtigungenReponse: Map<string, BenachrichtigungApiModel>
        = new Map<string, BenachrichtigungApiModel>();

    private static parseAktion(aktion: AktionFromApi | null): Aktion | null {
        if (aktion === null) {
            return null;
        }
        const data: Map<string, boolean | number | string> = new Map<string, boolean | number | string>();
        for (const [key, value] of Object.entries(aktion.data)) {
            data.set(key, value);
        }
        return {
            typ: aktion.typ,
            text: aktion.text,
            data: data,
        } as Aktion;
    }

    public async getBenachrichtigungen(query: BenachrichtigungQuery): Promise<BenachrichtigungenResponse> {
        return new Promise((resolve, reject) => {
            BenachrichtigungApiModel.pageSize = query.pageSize;
            let benachrichtigungenQuery = BenachrichtigungApiModel.query<BenachrichtigungApiModel>();
            if (typeof query.filter !== "undefined" && query.filter.length > 0) {
                benachrichtigungenQuery = benachrichtigungenQuery.where("tag", query.filter.toString());
            }
            if (typeof query.gelesen !== "undefined") {
                benachrichtigungenQuery = benachrichtigungenQuery.where("gelesen", query.gelesen.toString());
            }

            benachrichtigungenQuery
                .get(query.page)
                .then((response: PluralResponse<BenachrichtigungApiModel>) => {
                    if (query.page === 1) {
                        this.benachrichtigungenReponse.clear();
                    }
                    response
                        .getData()
                        .forEach((benachrichtigung: BenachrichtigungApiModel) => {
                            // wir wissen, dass an dieser Stelle die ApiId nicht undefined / null sein kann
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            this.benachrichtigungenReponse.set(benachrichtigung.getApiId()!, benachrichtigung);
                        });

                    const metaFromAPI = response.getHttpClientResponse().getData().meta as MetaFromApi;
                    const tagCount: Array<TagCount> = [];

                    Object.keys(metaFromAPI.tag_count).forEach(tag => {
                        tagCount.push({tag: tag, anzahl: metaFromAPI.tag_count[tag]} as TagCount);
                    });
                    const meta = {
                        size: metaFromAPI.size,
                        size_filtered: metaFromAPI.size_filtered,
                        size_ungelesen: metaFromAPI.size_ungelesen,
                        tagCount,
                    } as Meta;


                    let cachedBenachrichtigungen: Array<Benachrichtigung> = [];

                    if (this.benachrichtigungenReponse.size > 0) {
                        cachedBenachrichtigungen = Array
                            .from(this.benachrichtigungenReponse.values())
                            .map((benachrichtigung: BenachrichtigungApiModel) => ({
                                getId: () => benachrichtigung.getApiId(),
                                getText: () => benachrichtigung.getText(),
                                getGelesenAm: () => benachrichtigung.getGelesenAm(),
                                getErstelltAm: () => benachrichtigung.getErstelltAm(),
                                getTags: () => benachrichtigung.getTags(),
                                getAktion: () => BenachrichtigungEndpointImpl.parseAktion(benachrichtigung.getAktion()),
                            } as Benachrichtigung));
                    }
                    resolve({
                        getData: () => cachedBenachrichtigungen,
                        getMetaData: () => meta,
                    } as BenachrichtigungenResponse);
                })
                .catch(() => {
                    reject(new CouldNotPerformApiCallException(ErrorMessages.REQUEST));
                });
        });
    }


    public async deleteBenachrichtigung(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const benachrichtigung = this.benachrichtigungenReponse.get(id);
            if (benachrichtigung) {
                benachrichtigung
                    .delete()
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject(new CouldNotPerformApiCallException(ErrorMessages.DELETE));
                    });
            }
        });
    }

    public async deleteAll(): Promise<void> {
        return new Promise((resolve, reject) => {
            const array: Array<Promise<void>> = [];
            for (const benachrichtigung of this.benachrichtigungenReponse.values()) {
                array.push(benachrichtigung.delete());
            }
            Promise.allSettled(array)
                .then((results) => {
                    if (results.some((result) => result.status === "rejected")) {
                        reject(new CouldNotPerformApiCallException(ErrorMessages.DELETE));
                    }
                    resolve();
                })
                .catch(() => {
                    reject(new CouldNotPerformApiCallException(ErrorMessages.DELETE));
                });
        });
    }

    public async setAlleGelesen(): Promise<void> {
        return new Promise((resolve, reject) => {
            const array: Array<Promise<SaveResponse<BenachrichtigungApiModel>>> = [];
            for (const benachrichtigung of this.benachrichtigungenReponse.values()) {
                benachrichtigung.setGelesen(true);
                array.push(benachrichtigung.save());
            }
            Promise.allSettled(array)
                .then((results) => {
                    if (results.some((result) => result.status === "rejected")) {
                        reject(new CouldNotPerformApiCallException(ErrorMessages.READ_UNREAD));
                    }
                    resolve();
                })
                .catch(() => {
                    reject(new CouldNotPerformApiCallException(ErrorMessages.READ_UNREAD));
                });
        });
    }

    public async toggleBenachrichtigungGelesenUngelesen(id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const benachrichtigung = this.benachrichtigungenReponse.get(id);
            if (benachrichtigung) {
                if (benachrichtigung.getGelesenAm() !== null) {
                    benachrichtigung.setGelesen(false);
                    benachrichtigung.setGelesenAm(null);
                } else {
                    benachrichtigung.setGelesen(true);
                }
                benachrichtigung
                    .save()
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject(new CouldNotPerformApiCallException(ErrorMessages.READ_UNREAD));
                    });
            }
        });
    }
}
