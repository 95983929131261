import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20241016-ernaehrung",
        datum: "16.10.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Verbesserung Ernährungsgewohnheiten",
        lizenz: [LizenzEnum.PRO],
        content: [
            "Die Zählweise für Listenausgaben wurde aktualisiert: Personen, die keine Angaben machen möchten, werden "
            + "nun als 'ohne Einschränkung' kategorisiert. Die Anzahl der Personen, die sich bisher nicht zu ihrer "
            + "Ernährung geäußert haben, kann jetzt zusätzlich über die Variable "
            + "<code>[[$ohne_verpflegungsinfos]]</code> in Formularen und Vorlagen angezeigt werden.",
        ],
        aktions: [],
    },
];

export {
    insights,
};
