import type {EmpfaengerFilterSelection, Range} from "./EmpfaengerFilterSelection";
import type {Empfaenger} from "../Empfaenger";

export class EmpfaengerSelectionFilterer {
    public doesEmpfaengerMatchFilterCriteria(
        potEmpfaenger: Empfaenger,
        empfaengerFilterSelection: EmpfaengerFilterSelection,
    ): boolean {
        const matchesLeitungsTeam = !empfaengerFilterSelection.isLeitungsteamSelected
            ? true
            : potEmpfaenger.isInLeitungsteam;

        const matchesQuali1Selection
            = this.matchesQualiSelection(empfaengerFilterSelection.quali1Selections, potEmpfaenger.quali1Id);
        const matchesQuali2Selection
            = this.matchesQualiSelection(empfaengerFilterSelection.quali2Selections, potEmpfaenger.quali2Id);
        const matchesQuali3Selection
            = this.matchesQualiSelection(empfaengerFilterSelection.quali3Selections, potEmpfaenger.quali3Id);

        const matchesGroupsCriteria
            = this.matchesGroupCriteria(empfaengerFilterSelection.gruppenSelection, potEmpfaenger.grpId);

        const matchesLehrscheineCriteria
            = this.matchesLehrscheineCriteria(
                empfaengerFilterSelection.lehrscheineSelection,
                potEmpfaenger.lehrscheineIds,
            );

        return matchesLeitungsTeam
            && matchesQuali1Selection
            && matchesQuali2Selection
            && matchesQuali3Selection
            && matchesGroupsCriteria
            && matchesLehrscheineCriteria;
    }

    private matchesQualiSelection(qualiSelection: Range, qualiId: number | null): boolean {
        if (qualiId === null) {
            return qualiSelection.start === null && qualiSelection.end === null;
        }
        return !((qualiSelection.start !== null && qualiId < qualiSelection.start)
            || (qualiSelection.end !== null && qualiId > qualiSelection.end));
    }

    private matchesGroupCriteria(gruppenSelection: Array<number>, empfaengerGrpId: number): boolean {
        if (gruppenSelection.length === 0) {
            return true;
        }

        if (empfaengerGrpId === 0) {
            return gruppenSelection.includes(0);
        }

        const bitmask = gruppenSelection.reduce(
            (previousValue: number, currentValue: number) => previousValue | currentValue,
        );

        return Boolean(bitmask & empfaengerGrpId);
    }

    private matchesLehrscheineCriteria(lehrscheineSelections: Array<number> | null, lehrscheine: Array<number>): boolean {
        if (lehrscheineSelections === null) {
            return true;
        }
        if (lehrscheineSelections.length === 0) {
            return lehrscheine.length > 0;
        }
        for (const lehrscheinSelection of lehrscheineSelections) {
            if (lehrscheine.includes(lehrscheinSelection)) {
                return true;
            }
        }
        return false;
    }
}
