import type {Benachrichtigung} from "../../models/api/benachrichtigung/Benachrichtigung";
import {BenachrichtigungTags} from "../../models/api/benachrichtigung/BenachrichtigungTags";
import type {BenachrichtigungenViewModel} from "../../components/benachrichtigungen/BenachrichtigungenViewModel";
import type {IconInfo} from "../../models/api/benachrichtigung/IconInfo";
import type {Ref} from "vue";
import type {Tag} from "../../models/api/benachrichtigung/Tag";
import moment from "moment";
import {ref} from "vue";

const iconMap: Ref<Map<Tag, IconInfo>> = ref(new Map<Tag, IconInfo>());
iconMap.value.set(BenachrichtigungTags.AENDERUNG, {
    icon: "",
    title: "Änderung",
});
iconMap.value.set(BenachrichtigungTags.AUFGABE, {
    icon: "tasks_ffffff.svg",
    title: "Aufgabe",
});
iconMap.value.set(BenachrichtigungTags.AUSBILDUNG, {
    icon: "address-card_ffffff.svg",
    title: "Ausbildung",
});
iconMap.value.set(BenachrichtigungTags.DIENST, {
    icon: "siren_ffffff.svg",
    title: "Dienst",
});
iconMap.value.set(BenachrichtigungTags.DOKUMENT, {
    icon: "file-alt_ffffff.svg",
    title: "Dokument",
});
iconMap.value.set(BenachrichtigungTags.EINTEILUNG, {
    icon: "user-check_ffffff.svg",
    title: "Einteilung",
});
iconMap.value.set(BenachrichtigungTags.ENTFERNT, {
    icon: "",
    title: "Entfernt",
});
iconMap.value.set(BenachrichtigungTags.GEBURTSTAG, {
    icon: "birthday-cake_ffffff.svg",
    title: "Geburtstag",
});
iconMap.value.set(BenachrichtigungTags.FREIE_NACHRICHT, {
    icon: "solid/comment-alt-dots_ffffff.svg",
    title: "Freie Nachricht",
});
iconMap.value.set(BenachrichtigungTags.HANDLUNGSBEDARF, {
    icon: "exclamation_ffffff.svg",
    title: "Handlungsbedarf",
    bgColor: "#00c3d0",
});
iconMap.value.set(BenachrichtigungTags.KURS, {
    icon: "chalkboard-teacher_ffffff.svg",
    title: "Kurs",
});
iconMap.value.set(BenachrichtigungTags.MATERIAL, {
    icon: "box-full_ffffff.svg",
    title: "Material",
});
iconMap.value.set(BenachrichtigungTags.NEU, {
    icon: "",
    title: "Neu",
});
iconMap.value.set(BenachrichtigungTags.PERSOENLICH, {
    icon: "star_ffffff.svg",
    title: "für mich",
});
iconMap.value.set(BenachrichtigungTags.PERSONAL, {
    icon: "address-card_ffffff.svg",
    title: "Personal",
});
iconMap.value.set(BenachrichtigungTags.PINNWAND, {
    icon: "comments-alt_ffffff.svg",
    title: "Pinnwand",
});
iconMap.value.set(BenachrichtigungTags.RECHNUNG, {
    icon: "euro-sign_ffffff.svg",
    title: "Rechnungswesen",
});
iconMap.value.set(BenachrichtigungTags.SMS, {
    icon: "message-sms_ffffff.svg",
    title: "SMS",
});
iconMap.value.set(BenachrichtigungTags.SONSTIGES, {
    icon: "ellipsis-h_ffffff.svg",
    title: "Sonstiges",
});
iconMap.value.set(BenachrichtigungTags.SYSTEM, {
    icon: "cogs_ffffff.svg",
    title: "System",
});
iconMap.value.set(BenachrichtigungTags.TERMIN, {
    icon: "calendar-day_ffffff.svg",
    title: "Termin",
});
iconMap.value.set(BenachrichtigungTags.TERMINABSPRACHE, {
    icon: "calendar-exclamation_ffffff.svg",
    title: "Terminabsprache",
});
iconMap.value.set(BenachrichtigungTags.UEBERPRUEFUNG, {
    icon: "",
    title: "Überprüfung",
});
iconMap.value.set(BenachrichtigungTags.WARNUNG, {
    icon: "exclamation_ffffff.svg",
    title: "Warnung",
    bgColor: "#f5c150",
});

const getBenachrichtigungIconData = (benachrichtigung: Benachrichtigung): IconInfo => {
    const icon: IconInfo = iconMap.value.get(BenachrichtigungTags.SONSTIGES)!;
    icon.bgColor = "#63759A";

    const tags = benachrichtigung.getTags();

    if (tags.length > 0) {
        const iconPrimaer = iconMap.value.get(tags[0]);
        if (iconPrimaer) {
            iconPrimaer.bgColor = "#63759A";
            for (const tag of tags) {
                if (tag === BenachrichtigungTags.WARNUNG || tag === BenachrichtigungTags.HANDLUNGSBEDARF) {
                    iconPrimaer.bgColor = iconMap.value.get(tag)?.bgColor;
                }
            }
            return iconPrimaer;
        }
    }
    return icon;
};

const buildBenachrichtigungenFromResponse
    = (benachrichtigungenResponse: Array<Benachrichtigung>): Array<BenachrichtigungenViewModel> => {
        const benachrichtigungen: Array<BenachrichtigungenViewModel> = [];

        benachrichtigungenResponse.forEach(
            (benachr: Benachrichtigung) => {
                const erstelltAm = moment(benachr.getErstelltAm());
                let erstelltAmText: string;
                const erstellt_stunden = moment().diff(erstelltAm, "hours");
                if (erstellt_stunden > 24) {
                    erstelltAmText = `am ${erstelltAm.format("DD.MM.YYYY HH:mm")} Uhr`;
                } else if (erstellt_stunden === 0) {
                    const erstellt_minuten = moment().diff(erstelltAm, "minutes");
                    if (erstellt_minuten <= 1) {
                        erstelltAmText = "gerade eben";
                    } else {
                        erstelltAmText = `vor ${erstellt_minuten.toString(10)} Minuten`;
                    }
                } else if (erstellt_stunden === 1) {
                    erstelltAmText = "vor 1 Stunde";
                } else {
                    erstelltAmText = `vor ${erstellt_stunden.toString(10)} Stunden`;
                }

                const gelesen = benachr.getGelesenAm() !== null && benachr.getGelesenAm() !== "";
                const iconInfo = getBenachrichtigungIconData(benachr);
                const aktion = benachr.getAktion();
                const benachrichtigung: BenachrichtigungenViewModel = {
                    title: "",
                    content: benachr.getText(),
                    id: String(benachr.getId()),
                    gelesen: gelesen,
                    erstelltAm: erstelltAmText,
                    iconBezeichnung: iconInfo.icon,
                    iconTitel: iconInfo.title,
                    iconBgColor: iconInfo.bgColor!,
                    aktionText: aktion !== null ? aktion.text : null,
                    aktionData: aktion !== null ? aktion.data : new Map<string, boolean | number | string>(),
                    aktionTyp: aktion !== null ? aktion.typ : null,
                    tags: benachr.getTags(),
                };
                benachrichtigungen.push(benachrichtigung);
            },
        );
        return benachrichtigungen;
    };

const getBenachrichtigungIconTitle = (tag: Tag): string => {
    const icon = iconMap.value.get(tag);
    if (icon) {
        return icon.title;
    }
    return "Sonstiges";
};

export {
    buildBenachrichtigungenFromResponse,
    getBenachrichtigungIconTitle,
};
