import type Context from "./Context";
import type EndpointFactory from "./models/api/EndpointFactory";
import type {InjectionKey} from "vue";
import type {NotificationService} from "./services/NotificationService";

const keyContext = Symbol("InjectionKey Context") as InjectionKey<Readonly<Context>>;
const keyEndpointFactory = Symbol("InjectionKey EndpointFactory") as InjectionKey<EndpointFactory>;
const keyNotificationService = Symbol("InjectionKey NotificationService") as InjectionKey<NotificationService>;

export {
    keyContext,
    keyEndpointFactory,
    keyNotificationService,
};
