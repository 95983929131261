<template>
    <TransitionRoot
        as="template"
        :show="open"
        class="hiorg-vue-app"
    >
        <Dialog
            as="div"
            class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
            @close="open = false"
        >
            <div
                class="tw-flex tw-justify-center tw-min-h-screen tw-pt-4
                tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:p-0"
            >
                <TransitionChild
                    as="template"
                    enter="tw-ease-out tw-duration-300"
                    enter-from="tw-opacity-0"
                    enter-to="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leave-from="tw-opacity-100"
                    leave-to="tw-opacity-0"
                >
                    <DialogOverlay class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <TransitionChild
                    as="template"
                    enter="tw-ease-out tw-duration-300"
                    enter-from="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                    enter-to="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                    leave="tw-ease-in tw-duration-200"
                    leave-from="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                    leave-to="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                >
                    <div
                        class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4
                        tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8
                        sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6"
                    >
                        <div class="sm:tw-flex sm:tw-items-start">
                            <div
                                class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12
                                    tw-w-12 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10"
                            >
                                <ExclamationIcon
                                    class="tw-inline tw-h-6 tw-w-6 tw-text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
                                <DialogTitle
                                    as="h3"
                                    class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-mt-2"
                                    v-html="titel"
                                />
                                <div class="tw-mt-2">
                                    <p
                                        class="tw-text-sm tw-text-gray-500"
                                        v-html="content"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="tw-mt-5 sm:tw-mt-4 sm:tw-flex sm:tw-flex-row-reverse">
                            <button
                                id="btn-function"
                                type="button"
                                class="tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border
                                tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-font-medium
                                tw-text-white sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
                                @click="clickFunction"
                                v-html="buttonText"
                            />
                            <button
                                type="button"
                                class="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md
                                tw-border tw-border-gray-300 hover:tw-bg-gray-100 tw-shadow-sm tw-px-4 tw-py-2
                                tw-bg-white tw-font-medium tw-text-gray-700 sm:tw-mt-0 sm:tw-w-auto
                                sm:tw-text-sm"
                                @click="open = false"
                            >
                                Abbrechen
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ExclamationIcon} from "@heroicons/vue/outline";
import {computed} from "vue";

const props = defineProps<{
    modelValue: boolean;
    parentFunktion: () => void;
    titel: string;
    content: string;
    buttonText: string;
}>();

const emit = defineEmits<{
    (event: "update:modelValue", value: boolean): void;
}>();

const open = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});

const clickFunction = (): void => {
    open.value = false;
    props.parentFunktion();
};

</script>
<style>
    #btn-function {
        background-color: #fd5540 !important;
    }
    #btn-function:hover {
        background-color: #f44336 !important;
    }
</style>
