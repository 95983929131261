import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";
import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";

const insights: Array<Insight> = [
    {
        id: "20240917-blog",
        datum: "17.09.2024",
        kategorie: KategorieEnum.COMMUNITY,
        type: InsightTypeEnum.FEATURE,
        title: "Immer gut informiert: Der HiOrg-Server Blog",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE, LizenzEnum.FREE],
        content: [
            "Kennst Du den HiOrg-Server Blog? Hier findest Du umfassendere Berichte zu Themen, die Dich als Helfenden "
            + "wirklich interessieren: Neue Features stellen wir im Detail vor und erklären auch, wie Du sie am besten "
            + "nutzen kannst. Es gibt Tipps und Tricks zu bestehenden Funktionen, aktuelle News zu unseren "
            + "Kooperationen (bspw. mit der BG-QSEH), allgemein wichtige Informationen aus dem HiOrg-Universum und "
            + "zwischendurch immer mal wieder das ein oder andere Highlight aus unserem Arbeitsalltag."
        ],
        aktions: [
            {
                type: AktionTypeEnum.NACHRICHT,
                text: "Gewusst? Blogposts bequem per Mail erhalten",
                url: "https://info.hiorg-server.de/?p=10816",
            },
        ],
    },
];

export {
    insights,
};
