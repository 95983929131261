
export interface Range {
    start: number | null;
    end: number | null;
}

export class EmpfaengerFilterSelection {
    public quali1Selections: Range = {
        start: null,
        end: null,
    };

    public quali2Selections: Range = {
        start: null,
        end: null,
    };

    public quali3Selections: Range = {
        start: null,
        end: null,
    };

    public showOnlyPushEmpfaenger: boolean = false;

    public gruppenSelection: Array<number> = new Array<number>();

    public lehrscheineSelection: Array<number> | null = null;

    public isLeitungsteamSelected: boolean = false;
}
