import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20241001-mobile",
        datum: "01.10.2024",
        kategorie: KategorieEnum.MOBILGERAETE_APP,
        type: InsightTypeEnum.FEATURE,
        title: "Mobile-App Version 5.17.29",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE],
        content: [
            "In der Version wurde ein Fehler behoben, bei dem markierte Veranstaltungen nicht zu den Mehrfachaktionen "
            + "hinzugefügt wurden.",
        ],
        aktions: [],
    },
];

export {
    insights,
};
