import type {Empfaenger} from "./Empfaenger";
import {stringNormalize} from "../../utilities/common/stringNormalize";

const getKeyforEmpfaenger = (empfaenger: Empfaenger): string => {
    let key: string;
    switch (stringNormalize(empfaenger.name).charAt(0)) {
        case "a":
        case "b":
        case "c":
            key = "ABC";
            break;
        case "d":
        case "e":
        case "f":
            key = "DEF";
            break;
        case "g":
        case "h":
        case "i":
            key = "GHI";
            break;
        case "j":
        case "k":
        case "l":
            key = "JKL";
            break;
        case "m":
        case "n":
        case "o":
            key = "MNO";
            break;
        case "p":
        case "q":
        case "r":
            key = "PQR";
            break;
        case "s":
        case "t":
            key = "ST";
            break;
        case "u":
        case "v":
        case "w":
            key = "UVW";
            break;
        case "x":
        case "y":
        case "z":
            key = "XYZ";
            break;
        default:
            key = "Sonstige";
    }
    return key;
};

export function createSectionsForEmpfaenger(empfaenger: Array<Empfaenger>):
Map<string, Array<Empfaenger>> {
    const sectionWithEmpfaenger: Map<string, Array<Empfaenger>> = new Map<string, Array<Empfaenger>>();
    for (const empf of empfaenger) {
        const key = getKeyforEmpfaenger(empf);
        let array: Array<Empfaenger>;
        if (sectionWithEmpfaenger.has(key)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            array = sectionWithEmpfaenger.get(key)!;
        } else {
            array = [];
            sectionWithEmpfaenger.set(key, array);
        }
        array.push(empf);
    }
    if (sectionWithEmpfaenger.has("Sonstige")) {
        // Kategorie "Sonstige" ans Ende verschieben
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const dataSonstige = sectionWithEmpfaenger.get("Sonstige")!;
        sectionWithEmpfaenger.delete("Sonstige");
        sectionWithEmpfaenger.set("Sonstige", dataSonstige);
    }

    return sectionWithEmpfaenger;
}
