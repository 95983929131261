<template>
    <div class="tw-bg-white tw-pt-1 tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-gray-400">
        <div
            class="tw--ml-4 tw--mt-2 tw-px-2 tw-py-4 tw-flex tw-items-center tw-justify-between tw-flex-wrap
                   tw-sm:flex-nowrap"
        >
            <div class="tw-ml-4 tw-mt-2">
                <h3
                    v-if="isKlickbar"
                    class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 tw-cursor-pointer"
                    @click="titleClickFunction"
                >
                    {{ title }} {{ additionalTitle }}
                </h3>
                <h3
                    v-else
                    class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
                >
                    {{ title }} {{ additionalTitle }}
                </h3>
            </div>
            <div
                v-if="showCardLink"
                class="tw-ml-4 tw-mt-2 tw-shrink-0"
            >
                <a
                    class="tw-cursor-pointer tw-text-gray-700 tw-underline"
                    @click="linkFunction"
                >
                    {{ linkText }}
                </a>
            </div>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">

const props = defineProps<{
    title: string;
    linkText: string;
    additionalTitle: string;
    isKlickbar: boolean;
    cardTitleClickFunctionParent: () => void;
    cardLinkFunctionParent: () => void;
    showCardLink: boolean;
}>();

const titleClickFunction = (): void => {
    props.cardTitleClickFunctionParent();
};

const linkFunction = (): void => {
    props.cardLinkFunctionParent();
};
</script>
