<template>
    <div
        aria-live="assertive"
        class="tw-fixed tw-inset-0 tw-flex tw-items-start tw-p-6 tw-pointer-events-none"
    >
        <div class="tw-w-full tw-flex tw-flex-col tw-items-end tw-space-y-4">
            <transition
                v-for="[id, notification] in notifications"
                :key="id"
                enter-active-class="tw-transform tw-ease-out tw-duration-300 tw-transition"
                enter-from-class="tw-translate-y-2 tw-opacity-0 sm:tw-translate-y-0 sm:tw-translate-x-2"
                enter-to-class="tw-translate-y-0 tw-opacity-100 sm:tw-translate-x-0"
                leave-active-class="tw-transition tw-ease-in tw-duration-100"
                leave-from-class="tw-opacity-100"
                leave-to-class="tw-opacity-0"
            >
                <div
                    class="tw-max-w-sm tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-ring-1
                        tw-ring-black tw-ring-opacity-5 tw-overflow-hidden"
                >
                    <div class="tw-p-4">
                        <div class="tw-flex tw-items-start">
                            <div class="tw-flex-shrink-0">
                                <CheckCircleIcon
                                    v-if="notification.type === NotificationTypeEnum.SUCCESS"
                                    class="tw-h-6 tw-w-6 tw-mt-0.5 tw-text-green-400"
                                    aria-hidden="true"
                                />
                                <ExclamationIcon
                                    v-if="notification.type === NotificationTypeEnum.ERROR"
                                    class="tw-h-6 tw-w-6 tw-mt-0.5 tw-text-red-400"
                                    aria-hidden="true"
                                />
                                <ExclamationIcon
                                    v-if="notification.type === NotificationTypeEnum.WARNING"
                                    class="tw-h-6 tw-w-6 tw-mt-0.5 tw-text-yellow-400"
                                    aria-hidden="true"
                                />
                                <InformationCircleIcon
                                    v-if="notification.type === NotificationTypeEnum.INFO"
                                    class="tw-h-6 tw-w-6 tw-mt-0.5 tw-text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
                                <p class="tw-font-semibold">
                                    {{ getTitle(notification.type) }}
                                </p>
                                <p class="tw-mt-1">
                                    {{ notification.text }}
                                </p>
                            </div>
                            <div class="tw-ml-4 tw-flex-shrink-0 tw-flex">
                                <button
                                    type="button"
                                    class="tw-bg-white tw-rounded-md tw-inline-flex tw-text-gray-400
                                   hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2
                                   focus:tw-ring-offset-2 focus:tw-ring-gray-400-500"
                                    @click="notifications.delete(id)"
                                >
                                    <span class="tw-sr-only">Schlie&szlig;en</span>
                                    <XIcon
                                        class="tw-h-6 tw-w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
import {CheckCircleIcon, InformationCircleIcon} from "@heroicons/vue/outline";
import {ExclamationIcon, XIcon} from "@heroicons/vue/solid";
import {inject, ref} from "vue";
import type {NotificationEvent} from "../../../services/NotificationService";
import {NotificationTypeEnum} from "../../../services/NotificationTypeEnum";
import type {Ref} from "vue";
import {keyNotificationService} from "../../../injection-keys";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const notificationService = inject(keyNotificationService)!;
const notifications: Ref<Map<number, NotificationEvent>> = ref(new Map<number, NotificationEvent>());

const getTitle = (typ: NotificationTypeEnum): string => {
    switch (typ) {
        case NotificationTypeEnum.ERROR: return "Fehler";
        case NotificationTypeEnum.SUCCESS: return "Erfolg";
        case NotificationTypeEnum.WARNING: return "Warnung";
        case NotificationTypeEnum.INFO: return "Information";
        default: return "Fehler";
    }
};

let nextId = 1;
notificationService
    .onNotificationEventPublished((event: NotificationEvent) => {
        notifications.value.set(nextId++, event);
    });
</script>
