<template>
    <div>
        <div class="tw-flex tw-justify-between">
            <div class="tw-font-semibold">
                {{ titel }}
            </div>
            <div>
                <div class="tw-h-5">
                    <label
                        class="tw-font-medium tw-text-sm"
                    >
                        <input
                            v-model="alleAuswaehlen"
                            type="checkbox"
                            class="tw-form-checkbox focus:tw-ring-hiorg-teal-500 tw-h-4 tw-w-4 tw-rounded
                                   tw-text-hiorg-teal-500"
                        >
                        alle auswählen
                    </label>
                </div>
            </div>
        </div>
        <SwitchBadge
            v-for="badge in badgesArray"
            :key="badge.text"
            v-model="badge.isActive"
            color="#00c3d0"
            :text="badge.text"
            class="tw-mr-2 tw-mt-4 tw-cursor-pointer"
        />
    </div>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import type {Ref} from "vue";
import SwitchBadge from "../common/SwitchBadge.vue";

const props = defineProps<{
    titel: string;
    badges: Array<{id: number; bezeichnung: string}>;
    userSelection: Array<number>;
}>();

interface Badge {
    id: number;
    text: string;
    isActive: Ref<boolean>;
}

const emit = defineEmits<{
    (event: "update:userSelection", value: Array<number>): void;
}>();

const badgesArray: Ref<Array<Badge>> = ref([]);
for (const badge of props.badges) {
    badgesArray.value.push({
        id: badge.id,
        text: badge.bezeichnung,
        isActive: computed({
            get() {
                return props.userSelection.includes(badge.id);
            },
            set(value: boolean) {
                const selection = [...props.userSelection];
                const index = selection.indexOf(badge.id);
                if (value) {
                    if (index === -1) {
                        selection.push(badge.id);
                    }
                } else if (index !== -1) {
                    selection.splice(index, 1);
                }
                emit("update:userSelection", selection);
            },
        }),
    });
}

const alleAuswaehlen = computed({
    get() {
        return !badgesArray.value.some((badge) => !badge.isActive.value);
    },
    set(value: boolean) {
        emit("update:userSelection", value ? badgesArray.value.map((badge) => badge.id) : []);
    },
});

</script>

