import type {Insight} from "../../models/api/insight/Insight";
import {insights as insights20240612} from "./2024/20240612";
import {insights as insights20240808} from "./2024/20240808";
import {insights as insights20240827} from "./2024/20240827";
import {insights as insights20240904} from "./2024/20240904";
import {insights as insights20240917} from "./2024/20240917";
import {insights as insights20240925} from "./2024/20240925";
import {insights as insights20241001} from "./2024/20241001";
import {insights as insights20241011} from "./2024/20241011";
import {insights as insights20241016} from "./2024/20241016";
import {insights as insights20241031} from "./2024/20241031";

const insights: Array<Insight> = [
    ...insights20241031,
    ...insights20241016,
    ...insights20241011,
    ...insights20241001,
    ...insights20240925,
    ...insights20240917,
    ...insights20240904,
    ...insights20240827,
    ...insights20240808,
    ...insights20240612,
];

export {
    insights,
};
