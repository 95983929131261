import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20240808-vorlagen-asb",
        datum: "08.08.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Vorlagen und Bescheinigungen für ASB-Accounts",
        lizenz: [LizenzEnum.KURSE],
        content: [
            "Für Gliederungen des Arbeiter Samariter Bundes (ASB) sind in HiOrg-Server KURSE spezielle Vorlagen zum "
            + "Eindruck in die offiziellen Blanko-Teilnahmebescheinigungen hinterlegt. "
            + "Für Feedback zu durchgeführten Kursen haben wir nun den ASB-Rückmeldebogen mit Teilnehmerdatenblatt "
            + "umgesetzt. Wenn ihr den Rückmeldebogen nutzen möchtet schickt uns bitte eine kurze Nachricht."
        ],
        aktions: [
            {
                type: AktionTypeEnum.LINK,
                text: "Ausführliche Infos",
                url: "https://info.hiorg-server.de/2024/08/vorlagen-und-bescheinigungen-fuer-asb-accounts/",
            },
            {
                type: AktionTypeEnum.NACHRICHT,
                text: "Nachricht schreiben",
                url: "https://info.hiorg-server.de/kontakt/#kontaktform",
            },
        ],
    },
    {
        id: "20240808-materialverfuegbarkeit",
        datum: "08.08.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.VERBESSERTES_FEATURE,
        title: "Anpassungen am Layout der Verfügbarkeitsübersicht (Fahrzeuge / Material)",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE],
        content: [],
        aktions: [],
    },
    {
        id: "20240808-bugfix-qseh",
        datum: "08.08.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.BUGFIX,
        title: "Meldung von Kursen mit 8 oder 9 Terminen an <i>meineQSEH</i> möglich",
        lizenz: [LizenzEnum.KURSE],
        content: [],
        aktions: [],
    },
];

export {
    insights,
};
