<template>
    <SwitchGroup
        as="div"
        class="tw-flex tw-items-center"
    >
        <Switch
            v-model="enabled"
            :class="[enabled ? 'tw-bg-hiorg-teal-500' : 'tw-bg-gray-200',
                     'tw-inline-flex tw-flex-shrink-0 tw-h-6 tw-w-11 tw-border-2 ' +
                         'tw-border-transparent tw-rounded-full tw-cursor-pointer tw-transition-colors ' +
                         'tw-ease-in-out tw-duration-200 focus:tw-outline-none focus:tw-ring-2 ' +
                         'focus:tw-ring-offset-2 focus:tw-ring-hiorg-teal-500'
            ]"
        >
            <span
                aria-hidden="true"
                :class="[enabled ? 'tw-translate-x-5' : 'tw-translate-x-0',
                         'tw-pointer-events-none tw-inline-block tw-h-5 tw-w-5 ' +
                             'tw-rounded-full tw-bg-white tw-shadow tw-transform tw-ring-0 tw-transition ' +
                             'tw-ease-in-out  tw-duration-200'
                ]"
            />
        </Switch>
        <SwitchLabel
            as="span"
            class="tw-ml-3"
        >
            {{ text }}
        </SwitchLabel>
    </SwitchGroup>
</template>

<script setup lang="ts">
import {Switch, SwitchGroup, SwitchLabel} from "@headlessui/vue";
import {computed} from "vue";

const props = defineProps<{
    text: string;
    modelValue: boolean;
}>();

const emit = defineEmits<{
    (event: "update:modelValue", value: boolean): void;
}>();

const enabled = computed({
    get() {
        return props.modelValue;
    },
    set(value: boolean) {
        emit("update:modelValue", value);
    },
});
</script>

