<template>
    <button
        type="button"
        class="tw-rounded-full tw-p-2
            hover:tw-bg-hiorg-blau-200 focus-visible:tw-outline focus-visible:tw-outline-2
            focus-visible:tw-outline-offset-2 focus-visible:tw-outline-hiorg-blau-200"
    >
        <img
            src="/static/content/pics/fontawesome/trash-alt_71757a.svg"
            alt="Löschen"
            title="Löschen"
            class="tw-h-6 tw-cursor-pointer"
        >
    </button>
</template>

<script setup lang="ts">

</script>
