import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20240612-chat",
        datum: "12.06.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.FEATURE,
        title: "Chat zum Dienst",
        lizenz: [LizenzEnum.PRO],
        content: [
            "Zu jedem Dienst in HiOrg-Server PRO kann nun ein voll datenschutzkonformer Gruppenchat aktiviert werden. "
            + "Alle bei der Veranstaltung beteiligten Personen haben automatisch Zugriff drauf.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.LINK,
                text: "Ausführliche Infos",
                url: "https://info.hiorg-server.de/hilfe/wie-funktioniert-der-chat-zu-diensten/",
            },
        ],
    },
];

export {
    insights,
};
