<template>
    <div
        class="tw-rounded-md tw-bg-red-50 tw-p-4"
    >
        <div class="tw-flex">
            <div class="tw-flex-shrink-0">
                <ExclamationIcon
                    class="tw-h-5 tw-w-5 tw-text-red-400"
                    aria-hidden="true"
                />
            </div>
            <div class="tw-ml-3">
                <h3 class="tw-text-sm tw-font-medium tw-text-red-800">
                    Fehler
                </h3>
                <div class="tw-mt-2 tw-text-sm tw-text-red-700">
                    <p
                        v-for="cause in errorMessages"
                        :key="cause"
                    >
                        {{ cause }}
                    </p>
                </div>
            </div>
            <div
                v-if="hasCloseButton"
                class="tw-ml-auto tw-pl-3"
            >
                <div class="tw--mx-1.5 tw--my-1.5">
                    <button
                        type="button"
                        class="tw-inline-flex tw-bg-red-50 tw-rounded-md tw-p-1.5 tw-text-red-800
                               hover:tw-bg-red-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2
                               focus:tw-ring-offset-red-50 focus:tw-ring-red-600 tw-cursor-pointer"
                        @click="close"
                    >
                        <span class="tw-sr-only">Dismiss</span>
                        <XIcon
                            class="tw-h-5 tw-w-5"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ExclamationIcon, XIcon} from "@heroicons/vue/solid";

const props = defineProps<{
    errorMessages: Array<string>;
    hasCloseButton: boolean;
}>();

const emit = defineEmits<{
    (event: "close"): void;
}>();

const close = (): void => {
    emit("close");
};

</script>
