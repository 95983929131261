import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20241031-interview",
        datum: "31.10.2024",
        kategorie: KategorieEnum.COMMUNITY,
        type: InsightTypeEnum.INTERVIEW,
        title: "Feature Fokus HiOrg-Server PRO - Eure Meinung zählt!",
        lizenz: [LizenzEnum.PRO],
        content: [
            "Dank eurer wertvollen Einblicke aus der ersten Interviewrunde konnten wir <strong>fünf zentrale Themen</strong> identifizieren, die unser System und eure Arbeit direkt beeinflussen. "
            + "In Runde 2 möchten wir herausfinden, welche Themen euch am wichtigsten sind.",
            "Folgende Themen stehen zur Auswahl:<br>"
            + "<ul style=\"list-style: '🔥 ' inside;\">"
            + "<li>Angebot / Kostenvoranschlag im Rahmen einer Sanitätsdienst-Anforderung</li>"
            + "<li>Aufgabenverwaltung in der Mobilgeräte-App</li>"
            + "<li>Einsatzbericht nach Dienstabschluss</li>"
            + "<li>Materialverwaltung im Alltag</li>"
            + "<li>Zusammenarbeitsfunktionen für gemeinsame Veranstaltungen in HiOrg-Server</li>"
            + "</ul>",
            "Da das Interesse an unseren ersten Experteninterviews sehr hoch war, waren die verfügbaren Plätze schnell ausgebucht. "
            + "<strong>Diesmal wollen wir allen die Chance geben, flexibel teilzunehmen.</strong> "
            + "Danke für eure Unterstützung und dafür, dass ihr unsere Weiterentwicklung aktiv mitgestaltet!",
        ],
        aktions: [
            {
                type: AktionTypeEnum.TYPEBOT,
                text: "Jetzt mitentscheiden und uns zeigen, wo Dein größter Bedarf liegt 💬",
                url: "https://dialog.hiorg-server.de/feature-focus",
            },
        ],
    },
];

export {
    insights,
};
