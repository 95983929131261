import type {Gruppe} from "../../models/business/Gruppe";
import type {Lehrschein} from "../../models/business/Lehrschein";
import type {Qualifikation} from "../../models/business/Qualifikation";
import type {QualifikationsListe} from "../../models/business/QualifikationsListe";
import type {Stammdaten} from "../../models/business/Stammdaten";

interface QualifikationsListeJson {
    bezeichnung: string;
    qualifikationen: object;
}

interface StammdatenJson {
    gruppen: Array<Gruppe>;
    lehrscheine: Array<Lehrschein>;
    qualiListe1: QualifikationsListeJson | null;
    qualiListe2: QualifikationsListeJson | null;
    qualiListe3: QualifikationsListeJson | null;
    featurePush: {isFeaturePushAktiv: boolean};
}

function parseQualifikationsliste(parsedQualilist: QualifikationsListeJson | null): QualifikationsListe | null {
    if (parsedQualilist === null) {
        return null;
    }
    const qualiliste = new Map<number, Qualifikation>();
    for (const [key, value] of Object.entries(parsedQualilist.qualifikationen)) {
        qualiliste.set(Number(key), value as Qualifikation);
    }
    return {
        "bezeichnung": parsedQualilist.bezeichnung,
        qualiliste,
    } as QualifikationsListe;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function parseStammdaten<T extends Partial<Stammdaten>>(tagId: string): T {
    const stammdatenJsonString = document.getElementById(tagId)?.textContent ?? "{}";
    const parsedStammdaten = JSON.parse(stammdatenJsonString) as Partial<StammdatenJson>;
    const stammdaten: Partial<Stammdaten> = {};

    if (typeof parsedStammdaten.qualiListe1 !== "undefined") {
        stammdaten.qualiListe1 = parseQualifikationsliste(parsedStammdaten.qualiListe1);
    }
    if (typeof parsedStammdaten.qualiListe2 !== "undefined") {
        stammdaten.qualiListe2 = parseQualifikationsliste(parsedStammdaten.qualiListe2);
    }
    if (typeof parsedStammdaten.qualiListe3 !== "undefined") {
        stammdaten.qualiListe3 = parseQualifikationsliste(parsedStammdaten.qualiListe3);
    }

    if (typeof parsedStammdaten.gruppen !== "undefined") {
        const gruppen = new Map<number, Gruppe>();
        for (const gruppe of parsedStammdaten.gruppen) {
            gruppen.set(Number(gruppe.id), gruppe);
        }
        stammdaten.gruppen = gruppen;
    }

    if (typeof parsedStammdaten.lehrscheine !== "undefined") {
        const lehrscheine = new Map<number, Lehrschein>();
        for (const lehrscheineTmp of parsedStammdaten.lehrscheine) {
            lehrscheine.set(Number(lehrscheineTmp.id), lehrscheineTmp);
        }
        stammdaten.lehrscheine = lehrscheine;
    }
    if (typeof parsedStammdaten.featurePush !== "undefined") {
        stammdaten.isFeaturePushAktiv = parsedStammdaten.featurePush.isFeaturePushAktiv;
    }

    return stammdaten as T;
}
