import type ApiResult from "./ApiResult";
import {Model} from "coloquent";

export default abstract class ApiAction<T extends ApiResult> extends Model {
    protected readOnlyAttributes = ["result"];

    public getResult(): T {
        const result = this.getAttribute("result") as unknown ?? null;
        if (result === null) {
            throw new Error();
        }
        return result as T;
    }
}
