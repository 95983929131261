<template>
    <span
        class="tw-inline-flex tw-rounded-full tw-items-center tw-py-1 tw-pl-2.5 tw-pr-1 tw-font-medium
                 tw-bg-hiorg-teal-100 tw-text-hiorg-teal-600"
    >
        {{ text }}
        <button
            type="button"
            class="tw-flex tw-ml-0.5 tw-h-6 tw-w-6 tw-rounded-full tw-items-center
                   tw-justify-center hover:tw-bg-hiorg-teal-500 hover:tw-text-hiorg-teal-100 focus:tw-outline-none"
            @click="remove"
        >
            <span class="tw-sr-only">Löschen</span>
            <svg
                class="tw-h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
            >
                <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                />
            </svg>
        </button>
    </span>
</template>

<script setup lang="ts">

const props = defineProps<{
    id: number | string;
    text: string;
    parentFunction: (id: number | string) => void;
}>();

const remove = (): void => {
    props.parentFunction(props.id);
};

</script>
