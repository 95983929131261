import type {NotificationTypeEnum} from "./NotificationTypeEnum";
import {Subject} from "rxjs";

export interface NotificationEvent {
    type: NotificationTypeEnum;
    text: string;
}

export class NotificationService {
    private readonly notificationFlowSubject = new Subject<NotificationEvent>();

    public onNotificationEventPublished(callback: (nf: NotificationEvent) => void): void {
        this.notificationFlowSubject.subscribe(callback);
    }

    public notifyForEevent(event: NotificationEvent): void {
        this.notificationFlowSubject.next(event);
    }
}
