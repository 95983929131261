<template>
    <div>
        <label
            for="empfaenger-input"
            class="tw-block tw-border tw-border-gray-400 tw-shadow-sm tw-p-3 tw-w-full tw-min-h-40 tw-rounded-md
                   focus-within:tw-border-hiorg-teal-500 focus-within:tw-ring-1 focus-within:tw-ring-hiorg-teal-500"
        >
            <div class="tw-flex tw-flex-wrap tw-items-center">
                <BadgeWithRemoveButton
                    v-for="[userID, empf] in gewaehlteEmpfaenger.entries()"
                    :id="userID"
                    :key="userID"
                    :text="empf.vorname + ' ' + empf.name"
                    :parent-function="removeEmpfaenger"
                    class="tw-mr-2 tw-mb-3"
                />
                <div class="tw-border-l-2 tw-mb-3">
                    <input
                        id="empfaenger-input"
                        v-model="suchFeld"
                        type="text"
                        autocomplete="off"
                        class="tw-block tw-w-56 tw-ml-1 focus:tw-ring-transparent tw-form-input
                           tw-border-b tw-border-transparent tw-text-gray-700 tw-px-1 tw-py-0.5 tw-font-mono"
                        :placeholder="placeholder"
                    >
                </div>
            </div>
        </label>
        <div>
            <div
                aria-live="assertive"
                class="tw-w-full tw-pointer-events-none"
            >
                <div class="tw-space-y-4 tw-mt-1">
                    <transition>
                        <div
                            v-if="empfaengerErgebnis.size > 0"
                            class="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5
                                   tw-w-full tw-bg-gray-300 tw-shadow-lg tw-pointer-events-auto tw-border-gray-300
                                   tw-border"
                        >
                            <div
                                v-for="[key, empf] in empfaengerErgebnis.entries()"
                                :key="key"
                                class="tw-flex tw-items-center tw-p-3 hover:tw-bg-hiorg-teal-100 tw-cursor-pointer
                                   tw-bg-white"
                                @click="toggleEmpfaenger(empf.userId)"
                            >
                                <div class="tw-flex-none tw-w-6">
                                    <FontAwesomeIcon
                                        v-show="gewaehlteEmpfaenger.has(empf.userId)"
                                        icon="fa-solid fa-check-circle"
                                        class="tw-text-hiorg-teal-500"
                                        title="Gewählt"
                                    />
                                </div>
                                <span>{{ empf.vorname }} {{ empf.name }}</span>
                                <div class="tw-flex-none tw-w-6 tw-ml-2">
                                    <img
                                        v-if="empf.isPushAvailable"
                                        title="Push aktiviert"
                                        alt="Push aktiviert"
                                        src="/static/content/pics/glyphicons-2-0/glyphicons-basic-168-mobile-phone-vibrations_9ca3af.svg"
                                        class="tw-h-6"
                                    >
                                    <img
                                        v-else
                                        title="Push nicht aktiviert"
                                        alt="Push nicht aktiviert"
                                        src="/static/content/pics/glyphicons-2-0/glyphicons-basic-168-mobile-phone-vibrations_e5e7eb.svg"
                                        class="tw-h-6"
                                    >
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import BadgeWithRemoveButton from "../common/BadgeWithRemoveButton.vue";
import type {Empfaenger} from "./Empfaenger";
import type {Ref} from "vue";
import {stringNormalize} from "../../utilities/common/stringNormalize";

const props = defineProps<{
    empfaenger: Map<string, Empfaenger>;
    gewaehlteEmpfaenger: Map<string, Empfaenger>;
    placeholder: string;
}>();

const emit = defineEmits<{
    (event: "addEmpfaenger" | "removeEmpfaenger", value: Array<string>): void;
}>();

const suchFeld: Ref<string> = ref("");
const empfaengerErgebnis: Ref<Map<string, Empfaenger>> = ref(new Map<string, Empfaenger>());

const sucheTreffer = (eingabe: string): void => {
    empfaengerErgebnis.value.clear();
    if (eingabe.length === 0) {
        return;
    }
    const suche = stringNormalize(eingabe);
    for (const empfaenger of props.empfaenger.values()) {
        if (empfaenger.normalizedName.includes(suche)) {
            empfaengerErgebnis.value.set(empfaenger.userId, empfaenger);
        }
    }
};

const toggleEmpfaenger = (userId: string): void => {
    if (props.gewaehlteEmpfaenger.has(userId)) {
        emit("removeEmpfaenger", [userId]);
    } else {
        emit("addEmpfaenger", [userId]);
    }
    suchFeld.value = "";
};

const removeEmpfaenger = (userId: string): void => {
    emit("removeEmpfaenger", [userId]);
};

watch([suchFeld], (): void => {
    sucheTreffer(suchFeld.value);
}, {deep: true});
</script>
