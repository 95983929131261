import Organisation from "./models/api/Organisation";
import User from "./models/api/User";

export default class Context {
    private organisation_field: Organisation;

    private user_field: User;

    private simulated_field: boolean;

    private last_insight_gelesen: boolean;

    public constructor(
        organisation?: Organisation,
        user?: User,
        simulated?: boolean,
        last_insight_gelesen?: boolean,
    ) {
        this.organisation_field = organisation ?? new Organisation();
        this.user_field = user ?? new User();
        this.simulated_field = simulated ?? false;
        this.last_insight_gelesen = last_insight_gelesen ?? true;
    }

    public get user(): User {
        return this.user_field;
    }

    public set user(value: User) {
        this.user_field = value;
    }

    public get organisation(): Organisation {
        return this.organisation_field;
    }

    public set organisation(value: Organisation) {
        this.organisation_field = value;
    }

    public get simulated(): boolean {
        return this.simulated_field;
    }

    public set simulated(value: boolean) {
        this.simulated_field = value;
    }

    public get lastInsightGelesen(): boolean {
        return this.last_insight_gelesen;
    }

    public set lastInsightGelesen(value: boolean) {
        this.last_insight_gelesen = value;
    }
}
