import type {BenachrichtigungEndpoint} from "./benachrichtigung/BenachrichtigungEndpoint";
import {BenachrichtigungEndpointImpl} from "./benachrichtigung/BenachrichtigungEndpointImpl";
import type {FreieBenachrichtigungEndpoint} from "./freieBenachrichtigung/FreieBenachrichtigungEndpoint";
import {FreieBenachrichtigungEndpointImpl} from "./freieBenachrichtigung/FreieBenachrichtigungEndpointImpl";
import type {HilfeEndpoint} from "./hilfe/HilfeEndpoint";
import {HilfeEndpointImpl} from "./hilfe/HilfeEndpointImpl";

export default class EndpointFactory {
    private readonly benachrichtigungenEndpoint: BenachrichtigungEndpoint = new BenachrichtigungEndpointImpl();

    private readonly freieBenachrichtigungEndpoint: FreieBenachrichtigungEndpoint
        = new FreieBenachrichtigungEndpointImpl();

    private readonly hilfeEndpoint: HilfeEndpoint = new HilfeEndpointImpl();

    public getBenachrichtigungEndpoint(): BenachrichtigungEndpoint {
        return this.benachrichtigungenEndpoint;
    }

    public getFreieBenachrichtigungEndpoint(): FreieBenachrichtigungEndpoint {
        return this.freieBenachrichtigungEndpoint;
    }

    public getHilfeEndpoint(): HilfeEndpoint {
        return this.hilfeEndpoint;
    }
}
