import type {Empfaenger} from "./Empfaenger";
import {stringNormalize} from "../../utilities/common/stringNormalize";

export function parseEmpfaengerListe(empfaengerListe: string): Map<string, Empfaenger> {
    const parsedEmpfaengerList = JSON.parse(empfaengerListe) as Array<Empfaenger>;
    const empfaengerMap = new Map<string, Empfaenger>();

    for (const empfaenger of parsedEmpfaengerList) {
        empfaenger.normalizedName = stringNormalize(`${empfaenger.vorname} ${empfaenger.name}`);
        empfaengerMap.set(empfaenger.userId, empfaenger);
    }
    return empfaengerMap;
}
